import React from 'react'
import {Link, StaticQuery, graphql} from 'gatsby'
import '../../assets/index.scss'
import Navigation from '../Navbar/navbar'
import get from "lodash/get";
import styles from './layout.module.scss'


class Template extends React.Component {
    render() {
        const {location, children, classes} = this.props
        const menuLinks = get(this, 'props.data.site.siteMetadata.menuLinks')
        const rootPath = `${__PATH_PREFIX__}/`
        let header

        // if (location.pathname === rootPath || 'blog') {
        //     header = (
        //         <h1
        //             style={{
        //                 ...scale(1.5),
        //                 marginBottom: rhythm(1.5),
        //                 marginTop: 0,
        //             }}
        //         >
        //             <Link
        //                 style={{
        //                     boxShadow: 'none',
        //                     textDecoration: 'none',
        //                     color: 'inherit',
        //                 }}
        //                 to={'/'}
        //             >
        //                 Minj Tour & Travels
        //             </Link>
        //         </h1>
        //     )
        // } else {
        //     header = (
        //         <h3
        //             style={{
        //                 fontFamily: 'Montserrat, sans-serif',
        //                 marginTop: 0,
        //                 marginBottom: rhythm(-1),
        //             }}
        //         >
        //             <Link
        //                 style={{
        //                     boxShadow: 'none',
        //                     textDecoration: 'none',
        //                     color: 'inherit',
        //                 }}
        //                 to={'/'}
        //             >
        //                 Minj Tour & Travels
        //             </Link>
        //         </h3>
        //     )
        // }
        return (
            <div className={styles.layout}>
                <Navigation/>
                {children}
            </div>
        )
    }
}

export default Template