import React from 'react'
import Img from "gatsby-image"
import {TimelineMax} from "gsap";
import styles from './tourCard.module.scss'

class TourCard extends React.Component {

    render() {
        const {data} = this.props
        console.log(data)
        return (
            <div className={styles.tourCard + " columns has-background-white"}>
                <div className="column is-4 is-vertical-center is-paddingless imageWrapper">
                    <Img
                        fluid={data.feature.childImageSharp.fluid}
                        style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%",
                            zIndex:1
                        }}
                        alt="Travel Agency in Ranchi"
                    />
                    <h2 className="subtitle is-size-3 has-text-white has-padding-xl is-vertical-center" style={{
                        background: 'rgba(0, 0, 0, 0.3)',zIndex:2,height: '100%'
                    }}>
                        {data.name}
                    </h2>
                </div>
                <div className="column is-8 is-vertical-center">
                    <div className="tourHighlights content">
                        <h2 className="heading is-size-4 has-text-weight-bold">
                            {data.places.map((place) => place + ', ')}
                        </h2>
                        {
                            (data.tour) ? <h3 className="heading is-size-5">{data.tour} '19</h3> : null
                        }
                        {
                            (data.duration) ? <h3 className="heading is-size-5">{data.duration}</h3> : null
                        }
                        <h3 className="heading is-size-5">{data.travel} Days</h3>
                        {(data.cost !== 'TBA')? <h3 className="heading is-size-5">Starts at : &#8377; {data.cost}</h3>: <h3 className="heading is-size-5">Please Contact for Pricing</h3>}
                        {(data.highlights) ? <h2 className={styles.tourHighlights + " heading is-size-5"}>
                            {
                                data.highlights.map((highlight, index) =>
                                    <span key={index}>
                                        <abbr title={highlight.name}><i className={highlight.faIcon}/></abbr>
                                    </span>
                                )
                            }
                            </h2> : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default TourCard
