import React from 'react'
import {Link, graphql} from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import TourCard from '../components/TourCard'

const tours = [
    {
        name: 'Holyland Pilgrimage tour',
        title: 'Holyland',
        month: 'June',
        year: '19',
        duration: '10 Days',
        link: '/itineraries/tours/holyland/',
        cost: '1,05,000',
        places: [{name: 'Jordan'}, {name: 'Israel'}, {name: 'Palestine'}, {name: 'Egypt'}],
    },
    {
        name: 'Chennai Pilgrimage tour',
        title: 'Chennai',
        month: 'Feb',
        year: '19',
        duration: '15 Days',
        link: '/itineraries/tours/chennai/',
        cost: '65,000',
        places: [{name: 'Chennai'}, {name: 'Tamil'}, {name: 'Nadu'}],
    },
    {
        name: 'Andaman Islands',
        title: 'Andaman',
        month: 'July',
        year: '19',
        duration: '20 Days',
        link: '/itineraries/tours/andaman/',
        cost: '70,000',
        places: [{name: 'Havelock Island'}, {name: 'North Bay'}, {name: 'Viper Island'}],
    },
    {
        name: 'Europe Pilgrimage tour',
        title: 'Vatican',
        month: 'May',
        year: '19',
        duration: '25 Days',
        link: '/itineraries/tours/europe/',
        cost: '2,25,000',
        places: [{name: 'France'}, {name: 'Vatican'}, {name: 'Spain'}, {name: 'Germany'}],
    }
];

class Packages extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const siteDescription = get(
            this,
            'props.data.site.siteMetadata.description'
        )
        const tours = get(
            this,
            'props.data.groupTours.edges'
        )
        const pilgrimage = get(
            this,
            'props.data.pilgrimage.edges'
        )
        const packages = get(
            this,
            'props.data.honeymoon.edges'
        )

        return (
            <Layout location={this.props.location}>
                <Helmet
                    htmlAttributes={{lang: 'en'}}
                    meta={[{name: 'description', content: siteDescription}]}
                    title={siteTitle + " | Tours"}
                />
                <div className="text-montserrat"
                     style={{
                         background: '#2BC0E4',
                         background: '-webkit-linear-gradient(to right, #EAECC6, #2BC0E4)',
                         background: 'linear-gradient(to right, #EAECC6, #2BC0E4)'

                     }}
                >
                    <div className="level">
                        <img src="https://source.unsplash.com/1920x400/?travel" alt="" className="level-item"/>
                    </div>
                    <div className="container">
                        <div className="hero travel is-fullheight">
                            <div className="hero-head">
                                <h1 className="heading is-size-2">Travel around the world</h1>
                            </div>
                            <div className="hero-body">
                                <ul style={{width: '100%'}}>
                                    {
                                        packages.map((tour, index) => {
                                            return (
                                                <li key={index} className="has-margin-bottom-xl">
                                                    <Link className="is-block has-text-grey-dark" to={tour.node.fields.slug}>
                                                        <TourCard data={tour.node.frontmatter}/>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="hero tours is-fullheight">
                            <div className="hero-head">
                                <div className="title">
                                    <h1 className="heading is-size-2">Guided Tours around the world</h1>
                                </div>
                            </div>
                            <div className="hero-body">
                                <ul style={{width: '100%'}}>
                                    {
                                        pilgrimage.map((tour, index) => {
                                            return (
                                                <li key={index} className="has-margin-bottom-xl">
                                                    <Link className="is-block has-text-grey-dark" to={tour.node.fields.slug}>
                                                        <TourCard data={tour.node.frontmatter}/>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        tours.map((tour, index) => {
                                            return (
                                                <li key={index} className="has-margin-bottom-xl">
                                                    <Link className="is-block has-text-grey-dark" to={tour.node.fields.slug}>
                                                        <TourCard data={tour.node.frontmatter}/>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Packages

export const pageQuery = graphql`
  query {
  site {
      siteMetadata {
        title
        description
      }
    }
  honeymoon: allMarkdownRemark(filter: {frontmatter: {type: {eq: "honeymoon"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          class
          name
          travel
          cost
          feature {
              childImageSharp {
                fluid(maxWidth: 630) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          places
        }
      }
    }
  }
  groupTours: allMarkdownRemark(filter: {frontmatter: {type: {eq: "tour"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          class
          name
          travel
          duration
          places
          cost
          feature {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          highlights {
            name
            faIcon
          }
        }
      }
    }
  }
  pilgrimage: allMarkdownRemark(filter: {frontmatter: {type: {eq: "pilgrimage"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          class
          name
          travel
          tour
          cost
          feature {
              childImageSharp {
                fluid(maxWidth: 630) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          places
        }
      }
    }
  }
}`
